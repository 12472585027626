// extracted by mini-css-extract-plugin
export var background = "_2-0";
export var container = "_2-1";
export var contentItem = "_2-6";
export var modal = "_2-a";
export var modalCloseButton = "_2-8";
export var modalContent = "_2-3";
export var modalContentMain = "_2-5";
export var modalImageLogo = "_2-7";
export var modalLogo = "_2-9";
export var modalSideImage = "_2-4";
export var redoModal = "_2-2";