import { Modal as MuiModal } from "@mui/base/Modal";
import Fade from "@mui/material/Fade";
import { useHandler } from "@redotech/react-util/hook";
import { InfoModalVersion } from "@redotech/redo-model/team";
import FeaturedLaptopIcon from "@redotech/redo-web/arbiter-icon/featured-laptop-2.svg";
import FeaturedPackageCheckIcon from "@redotech/redo-web/arbiter-icon/featured-package-check.svg";
import FeaturedRefreshIcon from "@redotech/redo-web/arbiter-icon/featured-refresh-cw-3.svg";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import Exchange from "@redotech/redo-web/icon-old/exchange.svg";
import Flag from "@redotech/redo-web/icon-old/flag.svg";
import ModalCloseButton from "@redotech/redo-web/icon-old/modal-close-button.svg";
import Logo from "@redotech/redo-web/logo.svg";
import { ModalRootContext } from "@redotech/redo-web/modal-root";
import { Text } from "@redotech/redo-web/text";
import * as React from "react";
import { ReactElement, ReactNode, memo, useContext } from "react";
import * as modalV2 from "./modal-v2.module.css";
import * as modal from "./modal.module.css";
// import { Divider } from "@redotech/redo-web/divider";

const Background = React.forwardRef<HTMLDivElement, any>(function Background(
  { className, ownerState, ...props },
  ref,
) {
  return <div className={modal.background} ref={ref} {...props} />;
});

export const Modal = memo(function Modal({
  children,
  open,
  onClose,
}: {
  children: ReactElement;
  open: boolean;
  onClose?(): void;
}) {
  const root = useContext(ModalRootContext);

  return (
    <MuiModal
      className={modal.container}
      closeAfterTransition
      container={root}
      onClose={onClose}
      open={open}
      slots={{ backdrop: Background }}
    >
      <Fade in={open}>
        <div className={modalV2.modal}>{children}</div>
      </Fade>
    </MuiModal>
  );
});

const Button = memo(function Button({
  children,
  onClick,
}: {
  children: ReactNode | ReactNode[];
  onClick?(): void | Promise<void>;
}) {
  return (
    <button className={modal.buttonPrimary} onClick={onClick} type="button">
      {children}
    </button>
  );
});

export const RedoModal = memo(function RedoModal({
  open,
  onAdd,
  onClose,
  modalTextAdjustments,
  modalLogo,
  packageProtectionPlusEnabled,
  packageProtectionEnabled,
  version,
  logo,
  sideImage,
}: {
  open: boolean;
  onAdd?(): void | Promise<void>;
  onClose?: () => void;
  packageProtectionEnabled?: boolean;
  packageProtectionPlusEnabled?: boolean;
  modalTextAdjustments?: {
    text1: string;
    title1: string;
    text2: string;
    title2: string;
    text3: string;
    title3: string;
  };
  modalLogo?: string;
  version?: InfoModalVersion;
  logo?: string;
  sideImage?: string;
}) {
  if (version === InfoModalVersion.V2) {
    return (
      <Modal onClose={onClose} open={open}>
        <div className={modalV2.redoModal}>
          <ModalContentV2
            logo={logo}
            onClose={onClose}
            packageProtectionEnabled={packageProtectionEnabled}
            packageProtectionPlusEnabled={packageProtectionPlusEnabled}
            sideImage={sideImage}
          />
        </div>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose} open={open}>
      <div className={modal.redoModal}>
        <ModalContent
          modalLogo={modalLogo}
          modalTextAdjustments={modalTextAdjustments}
          onAdd={onAdd}
          onClose={onClose}
          packageProtectionPlusEnabled={packageProtectionPlusEnabled}
        />
      </div>
    </Modal>
  );
});

export const ModalContent = memo(function ModalContent({
  packageProtectionPlusEnabled,
  modalTextAdjustments,
  modalLogo,
  onAdd,
  onClose,
}: {
  packageProtectionPlusEnabled?: boolean;
  modalTextAdjustments?: {
    text1: string;
    title1: string;
    text2: string;
    title2: string;
    text3: string;
    title3: string;
  };
  modalLogo?: string;
  onClose?(): void;
  onAdd?(): void | Promise<void>;
}) {
  const onButtonClick = useHandler(async () => {
    onAdd && (await onAdd());
    onClose && onClose();
  });

  return (
    <>
      <div className={modal.modalLogo}>
        {(modalLogo && (
          <img alt="Logo" className={modal.modalImageLogo} src={modalLogo} />
        )) || <Logo />}
      </div>
      {onClose && (
        <div className={modal.modalCloseButton} onClick={onClose}>
          <ModalCloseButton />
        </div>
      )}

      <div className={modal.modalFeatures}>
        <Feature
          icon={<CheckCircle />}
          title={modalTextAdjustments?.title1 || "Add Redo"}
        >
          {modalTextAdjustments?.text1 ||
            "Qualifies you for FREE Returns if needed"}
        </Feature>
        <Feature
          icon={<Flag />}
          title={modalTextAdjustments?.title2 || "Receive your item"}
        >
          {modalTextAdjustments?.text2 || "Try it out"}
        </Feature>
        <Feature
          icon={<Exchange />}
          title={
            modalTextAdjustments?.title3 ||
            "Send it back for FREE if it doesn't work"
          }
        >
          {modalTextAdjustments?.text3 || "Quick and simple, at no extra cost"}
        </Feature>
      </div>

      {onAdd && (
        <div className={modal.modalAction}>
          <Button onClick={onButtonClick}>Add It</Button>
        </div>
      )}

      <div className={modal.modalFooter}>
        By purchasing Redo you agree and have read the{" "}
        <a
          href="https://www.getredo.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.getredo.com/terms-conditions"
          rel="noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </a>
        . Redo is subject to Merchant’s Return Policy.
        {packageProtectionPlusEnabled && (
          <>
            {" "}
            Shipping protection provided by{" "}
            <a
              href="https://shipsurance.com/redo/terms"
              rel="noreferrer"
              target="_blank"
            >
              Shipsurance
            </a>
            .
          </>
        )}
      </div>
    </>
  );
});

const Feature = memo(function Feature({
  icon,
  title,
  children,
}: {
  icon: ReactNode;
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <section className={modal.feature}>
      <div className={modal.featureBody}>
        <div className={modal.featureIcon}>{icon}</div>
        <h1 className={modal.featureTitle}>{title}</h1>
        <div className={modal.featureSummary}>{children}</div>
      </div>
    </section>
  );
});

export const ModalContentV2 = memo(function ModalContentV2({
  logo,
  onClose,
  sideImage,
  packageProtectionPlusEnabled,
  packageProtectionEnabled,
}: {
  packageProtectionPlusEnabled?: boolean;
  packageProtectionEnabled?: boolean;
  logo?: string;
  sideImage?: string;
  onClose?(): void;
}) {
  return (
    <div className={modalV2.modalContent}>
      {onClose && (
        <div className={modalV2.modalCloseButton} onClick={onClose}>
          <ModalCloseButton />
        </div>
      )}
      {sideImage && (
        <div
          className={modalV2.modalSideImage}
          style={{
            backgroundImage: `url(${sideImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
      <div className={modalV2.modalContentMain}>
        <div className={modalV2.modalLogo}>
          {(logo && (
            <img alt="Logo" className={modal.modalImageLogo} src={logo} />
          )) || <Logo />}
        </div>
        <Text fontSize="xl" fontWeight="semibold">
          Checkout with confidence
        </Text>
        <Text fontSize="sm" fontWeight="thin">
          Shop with confidence, knowing your purchases are protected every step
          of the way.
        </Text>
        <br />
        <div className={modalV2.contentItem}>
          <div>
            <FeaturedRefreshIcon height={32} width={32} />
          </div>
          <div>
            <Text fontSize="md" fontWeight="semibold">
              Free returns & exchanges
            </Text>
            <Text
              color="var(--redo-colors-gray-true-500)"
              fontSize="xs"
              fontWeight="thin"
            >
              Return or exchange your items for free. If you're not completely
              satisfied, we've got you covered.
            </Text>
          </div>
        </div>
        <br />
        {packageProtectionEnabled && (
          <>
            <div className={modalV2.contentItem}>
              <div>
                <FeaturedPackageCheckIcon height={32} width={32} />
              </div>
              <div>
                <Text fontSize="md" fontWeight="semibold">
                  Package protection
                </Text>
                <Text
                  color="var(--redo-colors-gray-true-500)"
                  fontSize="xs"
                  fontWeight="thin"
                >
                  Rest assured, if your package is lost, stolen, or damaged,
                  we've got you covered.
                </Text>
              </div>
            </div>
            <br />
          </>
        )}
        <div className={modalV2.contentItem}>
          <div>
            <FeaturedLaptopIcon height={32} width={32} />
          </div>
          <div>
            <Text fontSize="md" fontWeight="semibold">
              Easy return portal
            </Text>
            <Text
              color="var(--redo-colors-gray-true-500)"
              fontSize="xs"
              fontWeight="thin"
            >
              Skip all the back and forth, and submit your return in a few
              clicks.
            </Text>
          </div>
        </div>
        <br />
        <br />

        <Text
          color="var(--redo-colors-gray-true-500)"
          fontSize="xs"
          fontWeight="thin"
        >
          By purchasing Redo, you agree and have read the{" "}
          <a
            href="https://www.getredo.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.getredo.com/terms-conditions"
            rel="noreferrer"
            target="_blank"
          >
            Terms and Conditions
          </a>
          . Redo is subject to Merchant’s Return Policy.
          {packageProtectionPlusEnabled && (
            <>
              {" "}
              Shipping protection provided by{" "}
              <a
                href="https://shipsurance.com/redo/terms"
                rel="noreferrer"
                target="_blank"
              >
                Shipsurance
              </a>
              .
            </>
          )}
        </Text>
        {/* {packageProtectionPlusEnabled && (
            <>
              <br/>
              <Divider />
              <br/>
              <Text fontSize="xs" fontWeight="thin" color={'var(--redo-colors-gray-true-500)'}>
                THIS NEEDS TO CHANGE!!!! Redo Shipping Protection extends coverage for online purchases that are lost or damaged in transit, or stolen immediately after the carrier’s proof of delivery where Route traces the transportation. Route App, Inc. (Route) is the named insured on the shipping insurance policy (“Policy”); Route Insurance Solutions (“RIS”), Route’s licensed producer entity, procures the Policy from SEG Insurance Ltd. Route, through RIS, may receive compensation for its services. With respect to goods purchased on a subscription basis, adding Route Shipping Protection to an initial subscription purchase will automatically add Route Shipping Protection and the premium to all subsequent installments of the subscription. Route Shipping Protection is neither returnable nor refundable.
              </Text>
            </>
          )} */}
      </div>
    </div>
  );
});
