// extracted by mini-css-extract-plugin
export var background = "_1-0";
export var buttonPrimary = "_1-1";
export var container = "_1-2";
export var feature = "_1-5";
export var featureBody = "_1-6";
export var featureIcon = "_1-7";
export var featureSummary = "_1-9";
export var featureTitle = "_1-8";
export var modal = "_1-f";
export var modalAction = "_1-e";
export var modalCloseButton = "_1-d";
export var modalFeatures = "_1-4";
export var modalFooter = "_1-a";
export var modalImageLogo = "_1-b";
export var modalLogo = "_1-c";
export var redoModal = "_1-3";